import {
  UserOutlined,
  ClockCircleOutlined,
  HeartFilled,
  HeartOutlined,
  EditOutlined,
  CalendarOutlined,
  MailOutlined,
  CloseOutlined,
  PushpinOutlined,
  PushpinFilled,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Col,
  Progress as AntProgress,
  Tag as AntTag,
  Tooltip,
  Space,
} from 'antd'
import { ObjectId } from 'bson'
import dayjs, { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled, { AnyStyledComponent } from 'styled-components'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { formatDuration } from '@lms-shared-patterns/utils'
import {
  ActiveCoursesQuery,
  CoursesQuery,
  NewCoursesQuery,
  LikeCourseMutation,
  Unit,
  AssignedCoursesQuery,
  PinCourseMutation,
} from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { Can } from '../../../auth/components/Can'
import { calcProgress } from '../../helpers/calc-progress'
import { AddToCalendarModal } from '../add-to-calendar-modal'
import { Arrow } from '../arrow/Arrow'

import LIKE_COURSE_MUTATION from './../../mutations/like-course.graphql'
import PIN_COURSE_MUTATION from './../../mutations/pin-course.graphql'
import {
  CardWrapper,
  CourseCardContents,
  CourseCardMeta,
  CourseImage,
  CourseImageOverlay,
  CourseTitle,
  LastActive,
  Tag,
  DraftTag,
  ProgressLabel,
  CloseButton,
} from './CourseCard.style'

extend(utc)
extend(timezone)
extend(relativeTime)

const Progress = styled(AntProgress as unknown as AnyStyledComponent)`
  line-height: 0;
  display: block;

  .ant-progress-inner {
    display: block;
  }
`

export const ArchivedBanner = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
`

export const CourseSkeletonCard = () => (
  <Col sm={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
    <Skeleton
      enableAnimation={true}
      style={{ paddingTop: '66.666666%', lineHeight: 0 }}
    ></Skeleton>
    <CourseTitle style={{ lineHeight: '100%' }}>
      <Skeleton />
    </CourseTitle>
    <Skeleton height={20} />
    <LastActive>
      <Skeleton />
    </LastActive>
  </Col>
)

export const CourseCard = ({
  card = false,
  course,
  progress = false,
  cardsPerRow = {
    sm: 12,
    lg: 6,
    xl: 6,
  },
  draft = false,
  assignment,
  hide,
  onClick,
  likes = false,
  pinnable = false,
  addToCalendar = false,
  archived = false,
}: {
  card?: boolean
  course:
    | NonNullable<ActiveCoursesQuery['fetchActiveCourses']>[0]
    | NewCoursesQuery['fetchNewCourses'][0]
    | CoursesQuery['fetchCourses']['results'][0]
  progress?: boolean
  cardsPerRow?: {
    sm?: number
    lg?: number
    xl?: number
  }
  draft?: boolean
  pinnable?: boolean
  assignment?: AssignedCoursesQuery['assignedToMe'][0]['assignment']
  hide?: (id: string) => void
  likes?: boolean
  addToCalendar?: boolean
  onClick?: (course: CoursesQuery['fetchCourses']['results'][0]) => void
  archived?: boolean
}) => {
  const auth = useAuth()
  const branch = useBranch()
  const [hover, setHover] = useState<boolean>(false)
  const [showAddToCalendar, setShowAddToCalendar] = useState<boolean>(false)

  const [likeCourse] = useMutation<LikeCourseMutation>(LIKE_COURSE_MUTATION, {
    variables: {
      id: course._id,
    },
  })

  const [pinCourse] = useMutation<PinCourseMutation>(PIN_COURSE_MUTATION, {
    variables: {
      id: course._id,
    },
  })

  const progressPct =
    'contents' in course
      ? calcProgress(
          course.translation.contents.reduce<Unit[]>((prev, cur) => {
            return [...prev, ...(cur.units as Unit[])]
          }, [])
        )
      : 0

  return (
    <>
      <Col
        className={card ? 'course-card' : 'course-no-card'}
        sm={{ span: cardsPerRow.sm }}
        lg={{ span: cardsPerRow.lg }}
        xl={{ span: cardsPerRow.xl }}
        style={{
          cursor: 'pointer',
          marginBottom: 30,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => onClick?.(course)}
      >
        <CardWrapper card={card}>
          <div style={{ position: 'relative' }}>
            {!draft && (
              <Space
                style={{ position: 'absolute', right: 12, top: 12, zIndex: 5 }}
              >
                {likes && (
                  <Tooltip
                    mouseEnterDelay={0.2}
                    overlay={
                      course.liked
                        ? t({
                            id: 'course.card.action.remove_from_likes',
                            message: 'Verwijderen uit favorieten',
                          })
                        : t({
                            id: 'course.card.action.add_to_likes',
                            message: 'Toevoegen aan favorieten',
                          })
                    }
                  >
                    <Button
                      style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                      }}
                      shape="circle"
                      type="text"
                      icon={
                        course.liked ? (
                          <HeartFilled style={{ color: 'var(--like-color)' }} />
                        ) : (
                          <HeartOutlined
                            style={{ color: 'var(--like-color)' }}
                          />
                        )
                      }
                      onClick={(e) => {
                        e.stopPropagation()
                        likeCourse({
                          refetchQueries: ['likedCourses'],
                        })
                        return false
                      }}
                    />
                  </Tooltip>
                )}
                {new ObjectId(branch?._id).equals(course?.ownerBranch || '') &&
                  pinnable && (
                    <Can
                      I={PermissionAction.PIN}
                      a={PermissionObjectType.BRANCH_COURSE}
                    >
                      <Button
                        style={{
                          backgroundColor: 'rgba(255, 255, 255, 0.3)',
                        }}
                        shape="circle"
                        type="text"
                        icon={
                          course.pinned ? (
                            <PushpinFilled
                              style={{ color: 'var(--ant-primary)' }}
                            />
                          ) : (
                            <PushpinOutlined
                              style={{ color: 'var(--ant-primary)' }}
                            />
                          )
                        }
                        onClick={(e) => {
                          e.stopPropagation()
                          pinCourse({ refetchQueries: ['courses'] })
                          return false
                        }}
                      />
                    </Can>
                  )}
              </Space>
            )}

            {!draft && addToCalendar && (
              <Tooltip
                mouseEnterDelay={0.2}
                overlay={t({
                  id: 'course.card.action.add_to_calendar',
                  message: 'Toevoegen aan agenda',
                })}
              >
                <Button
                  style={{
                    position: 'absolute',
                    left: 12,
                    top: 12,
                    zIndex: 5,
                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                  }}
                  shape="circle"
                  type="text"
                  icon={<CalendarOutlined style={{ color: 'white' }} />}
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowAddToCalendar(true)
                    return false
                  }}
                />
              </Tooltip>
            )}

            {draft && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  top: 0,
                  bottom: 0,
                  margin: 'auto',
                  zIndex: 5,
                }}
              >
                <DraftTag>
                  <EditOutlined /> <Trans id="course.tag.draft">Concept</Trans>
                </DraftTag>
              </div>
            )}
            <CourseImage
              rounded={!card}
              src={course.image?.url || '/course-image-placeholder.svg'}
              width={course.image?.width || 720}
              height={course.image?.height || 480}
              loading="lazy"
              alt={course.image?.alt || ''}
              style={{
                opacity: draft ? 0.5 : 1,
                filter: archived
                  ? 'grayscale(100%) brightness(1.2) contrast(0.8)'
                  : 'none',
              }}
            />
            {archived && <ArchivedBanner>Archief</ArchivedBanner>}
            {!draft && (
              <CourseImageOverlay rounded={!card} hover={hover}>
                <>
                  <Trans id="course.card.action.view_course">
                    Bekijk opleiding
                  </Trans>
                </>
                <Arrow />
              </CourseImageOverlay>
            )}
          </div>
          {card && (
            <div style={{ backgroundColor: '#FFF', height: '100%' }}>
              <CourseCardContents style={{ opacity: draft ? 0.5 : 1 }}>
                {'meta' in course &&
                  !assignment &&
                  course.meta?.publication_date &&
                  dayjs(course.meta.publication_date).diff() >
                    -1000 * 60 * 60 * 24 * 5 && (
                    <Tag>
                      <Trans id="course.tag.new">Nieuw</Trans>
                    </Tag>
                  )}
                {course.pinned && pinnable && (
                  <Can
                    not
                    I={PermissionAction.PIN}
                    a={PermissionObjectType.BRANCH_COURSE}
                  >
                    <Button
                      shape="circle"
                      type="text"
                      icon={
                        <PushpinFilled
                          style={{
                            color: 'var(--ant-primary)',
                          }}
                        />
                      }
                      style={{
                        position: 'absolute',
                        top: 12,
                        right: 12,
                      }}
                    />
                  </Can>
                )}
                {(course.lecturer ||
                  course.duration ||
                  ('meta' in course &&
                    (course.meta?.publication_date ||
                      course.meta?.revision_date))) &&
                  !assignment && (
                    <p
                      style={{
                        marginBottom: 8,
                        paddingRight: course.pinned ? 10 : 0,
                      }}
                    >
                      {course.lecturer && (
                        <CourseCardMeta>
                          <UserOutlined />
                          {course.lecturer}
                        </CourseCardMeta>
                      )}
                      {course.duration && (
                        <CourseCardMeta>
                          <ClockCircleOutlined />
                          {formatDuration(course.duration * 60)}
                        </CourseCardMeta>
                      )}
                      {'meta' in course &&
                        (course.meta?.publication_date ||
                          course.meta?.revision_date) && (
                          <CourseCardMeta>
                            <CalendarOutlined />
                            {dayjs(
                              course.meta.revision_date ||
                                course.meta.publication_date
                            ).format('DD/MM/YYYY')}
                          </CourseCardMeta>
                        )}
                    </p>
                  )}
                <CourseTitle style={{ fontWeight: 400, marginTop: 0 }}>
                  {course.translation.name}
                </CourseTitle>
                {course.certificationType?.length ? (
                  <Space style={{ marginTop: 8, marginBottom: 8 }}>
                    {course.certificationType
                      ?.filter(
                        (c) =>
                          auth?.user?.certificationType?.find((ct) =>
                            new ObjectId(ct._id).equals(c._id)
                          )
                      )
                      .map((c) => <AntTag key={c._id}>{c.name}</AntTag>)}
                  </Space>
                ) : null}
                {assignment && (
                  <p style={{ marginTop: 8 }}>
                    <Trans id="course.card.assigned_by">
                      Toegewezen door {assignment.assignor.firstName}
                    </Trans>{' '}
                    {assignment.assignor.lastName}
                    {assignment && assignment.message && (
                      <span style={{ marginLeft: 6 }}>
                        <Tooltip title={assignment.message}>
                          <MailOutlined style={{ fontSize: 12 }} />
                        </Tooltip>
                      </span>
                    )}
                  </p>
                )}
                {assignment?.mandatory && (
                  <Tag>
                    <Trans id="course.tag.mandatory">Verplicht</Trans>
                  </Tag>
                )}
                {card &&
                  progress &&
                  course.my_activity?.last_activity &&
                  progressPct >= 0 &&
                  progressPct < 100 && (
                    <>
                      <Progress
                        percent={Math.max(progressPct, 2)}
                        showInfo={false}
                        size={'small'}
                        strokeWidth={6}
                        trailColor={'#F0F0F0'}
                        style={{ marginTop: 16 }}
                      />
                      {progressPct > 0 ? (
                        <ProgressLabel>
                          <Trans id="course.card.progress">
                            voor {Math.ceil(progressPct)}% voltooid
                          </Trans>
                        </ProgressLabel>
                      ) : (
                        <ProgressLabel>
                          <Trans id="course.card.started">gestart</Trans>
                        </ProgressLabel>
                      )}
                    </>
                  )}
                {card && progress && progressPct >= 100 && (
                  <>
                    <Progress
                      percent={100}
                      showInfo={false}
                      size={'small'}
                      strokeWidth={6}
                      strokeColor={'success'}
                      trailColor={'#F0F0F0'}
                      style={{ marginTop: 16 }}
                    />
                    <ProgressLabel
                      style={{ color: 'var(--ant-success-color)' }}
                    >
                      <Trans id="course.card.completed">
                        opleiding voltooid
                      </Trans>
                    </ProgressLabel>
                  </>
                )}
              </CourseCardContents>
            </div>
          )}
          {assignment && assignment.deadline && (
            <Deadline error={dayjs().diff(assignment.deadline) > 0}>
              <strong>
                <Trans id="course.card.deadline">Deadline</Trans>
              </strong>
              : {dayjs().to(assignment.deadline)}
            </Deadline>
          )}
        </CardWrapper>
        {!card && (
          <div style={{ position: 'relative' }}>
            <CourseTitle style={{ paddingRight: 24 }}>
              {course.translation.name}
            </CourseTitle>
            <CloseButton
              type={'link'}
              onClick={(e) => {
                e.stopPropagation()
                hide && hide(course._id)
              }}
            >
              <CloseOutlined />
            </CloseButton>
          </div>
        )}
        {progress && !card && (
          <div style={{ marginTop: 12, marginBottom: 6 }}>
            <Progress
              percent={Math.max(2, progressPct)}
              showInfo={false}
              size={'small'}
            />
          </div>
        )}
        {progress && !card && (
          <LastActive>
            <Trans id="course.card.last_activity">Laatst bekeken</Trans>:{' '}
            {dayjs(course.my_activity?.last_activity)
              .tz('Europe/Brussels')
              .fromNow()}
          </LastActive>
        )}
      </Col>
      <AddToCalendarModal
        open={showAddToCalendar}
        onClose={() => setShowAddToCalendar(false)}
        name={course.translation.name}
        duration={course.duration}
        description={course.translation.description}
        deadline={assignment?.deadline}
        url={`https://${window.location.host}/courses/${course.slug}`}
      />
    </>
  )
}

export const Deadline = styled.div`
  background-color: var(--ant-primary-color);
  ${({ error }: { error: boolean }) =>
    error && `background-color: var(--ant-error-color); color: #FFF;`}
  padding: 0.5rem 0.75rem;
  text-align: center;
`
