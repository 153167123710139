/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable sonarjs/no-identical-functions */
import { useMutation, useQuery } from '@apollo/client'
import { Trans } from '@lingui/macro'
import { Button, Grid } from 'antd'
import { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useContext, useMemo, useRef, useState } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from 'react-router-dom'
import Slider, { Settings } from 'react-slick'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  NewCoursesQuery,
  ActiveCoursesQuery,
  CourseCountQuery,
  PopularCoursesQuery,
  AssignedCoursesQuery,
  HideCourseActivityMutation,
} from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'

import { AbilityContext } from '../../../auth/components/Can'
import {
  CourseCard,
  CourseSkeletonCard,
} from '../../../courses/components/course-card/CourseCard'
import { SliderArrowLeft, SliderArrowRight } from '../../../shared/icons/icons'
import { Container } from '../../../shared/layout/Layout.style'

import HIDE_COURSE_ACTIVITY_MUTATION from './../../mutations/hide-course-activity.graphql'
import ACTIVE_COURSES_QUERY from './../../queries/active-courses.graphql'
import ASSIGNED_COURSES_QUERY from './../../queries/assigned-courses.graphql'
import COURSE_COUNT_QUERY from './../../queries/course-count.graphql'
import NEW_COURSES_QUERY from './../../queries/new-courses.graphql'
import POPULAR_COURSES_QUERY from './../../queries/popular-courses.graphql'
import {
  CoursesRow,
  Section,
  SectionTitle,
  SectionTitleWrapper,
} from './Dashboard.style'

extend(utc)
extend(timezone)
extend(relativeTime)

const settings: Settings = {
  draggable: false,
  dots: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  arrows: false,
  infinite: false,
  speed: 400,
  slidesToShow: 4,
  slidesToScroll: 1,
}

export const Dashboard = () => {
  const ability = useContext(AbilityContext)
  const { user } = useAuth()
  const navigate = useNavigate()

  const { data: activeCoursesData, loading: activeCoursesLoading } =
    useQuery<ActiveCoursesQuery>(ACTIVE_COURSES_QUERY, {
      fetchPolicy: 'cache-and-network',
    })

  const { data: courseCount } = useQuery<CourseCountQuery>(COURSE_COUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const { data: newCoursesData } = useQuery<NewCoursesQuery>(
    NEW_COURSES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    }
  )

  const { data: popularCoursesData } = useQuery<PopularCoursesQuery>(
    POPULAR_COURSES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    }
  )

  const { data: assignedCoursesData } = useQuery<AssignedCoursesQuery>(
    ASSIGNED_COURSES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    }
  )

  const [hideCourseActivity] = useMutation<HideCourseActivityMutation>(
    HIDE_COURSE_ACTIVITY_MUTATION,
    {
      refetchQueries: ['activeCourses'],
    }
  )

  const activeSliderRef = useRef<Slider>(null)

  const [activeSliderCurrent, setActiveSliderCurrent] = useState<number>(0)

  const breakpoint = Grid.useBreakpoint()

  const showActiveSlider = useMemo(() => {
    if (activeCoursesData?.fetchActiveCourses) {
      if (activeCoursesData?.fetchActiveCourses.length > 4 && breakpoint.lg)
        return true
      if (activeCoursesData?.fetchActiveCourses.length > 2 && !breakpoint.lg)
        return true
      return !!(
        activeCoursesData?.fetchActiveCourses.length > 1 && !breakpoint.sm
      )
    } else {
      return false
    }
  }, [breakpoint, activeCoursesData])

  const activeSliderCanPrev = useMemo(() => {
    return activeSliderCurrent > 0 ? false : true
  }, [activeSliderCurrent])

  const activeSliderCanNext = useMemo(() => {
    const visibleSlides = breakpoint.lg ? 4 : breakpoint.md ? 2 : 1
    if (!activeCoursesData?.fetchActiveCourses) return true
    return activeSliderCurrent >=
      activeCoursesData?.fetchActiveCourses.length - visibleSlides
      ? true
      : false
  }, [activeSliderCurrent, activeCoursesData, breakpoint])

  const PopularCourses = ({ dark }: { dark: boolean }) => {
    const popularSliderRef = useRef<Slider>(null)
    const [popularSliderCurrent, setPopularSliderCurrent] = useState<number>(0)

    const showPopularSlider = useMemo(() => {
      if (popularCoursesData?.fetchPopularCourses) {
        if (popularCoursesData?.fetchPopularCourses.length > 4 && breakpoint.lg)
          return true
        if (
          popularCoursesData?.fetchPopularCourses.length > 2 &&
          !breakpoint.lg
        )
          return true
        return !!(
          popularCoursesData?.fetchPopularCourses.length > 1 && !breakpoint.sm
        )
      } else {
        return false
      }
    }, [breakpoint, popularCoursesData])

    const popularSliderCanPrev = useMemo(() => {
      return popularSliderCurrent > 0 ? false : true
    }, [popularSliderCurrent])

    const popularSliderCanNext = useMemo(() => {
      const visibleSlides = breakpoint.lg ? 4 : breakpoint.md ? 2 : 1
      if (!popularCoursesData?.fetchPopularCourses) return true
      return popularSliderCurrent >=
        popularCoursesData?.fetchPopularCourses.filter(
          (course) => !course.my_activity?.completed
        ).length -
          visibleSlides
        ? true
        : false
    }, [popularSliderCurrent, popularCoursesData, breakpoint])
    return (
      <Section dark={dark}>
        <Container>
          <SectionTitleWrapper>
            <SectionTitle>
              <Trans id="dashboard.popular.title">
                Meest bekeken opleidingen in de <strong>afgelopen week</strong>
              </Trans>
            </SectionTitle>
            <Button
              onClick={() => navigate('/courses')}
              type="text"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <>
                <Trans id="dashboard.popular.see_all">
                  Bekijk alle opleidingen
                </Trans>
                {courseCount?.fetchCourses.count
                  ? ` (${courseCount?.fetchCourses.count})`
                  : ``}
              </>
            </Button>
          </SectionTitleWrapper>
          {popularCoursesData &&
            popularCoursesData.fetchPopularCourses.length > 0 && (
              <>
                {showPopularSlider ? (
                  // @ts-ignore
                  <Slider
                    ref={popularSliderRef}
                    beforeChange={(_, currentSlide) =>
                      setPopularSliderCurrent(currentSlide)
                    }
                    {...settings}
                  >
                    {popularCoursesData.fetchPopularCourses
                      .filter((course) => !course.my_activity?.completed)
                      .map(
                        (
                          course: PopularCoursesQuery['fetchPopularCourses'][0]
                        ) => (
                          <CourseCard
                            key={course._id}
                            course={course}
                            card
                            progress
                            cardsPerRow={{
                              sm: 24,
                              lg: 24,
                              xl: 24,
                            }}
                            likes={ability.can(
                              PermissionAction.READ,
                              PermissionObjectType.LIKE
                            )}
                            pinnable={false}
                            addToCalendar={ability.can(
                              PermissionAction.ADD_TO_CALENDAR,
                              PermissionObjectType.COURSE
                            )}
                            onClick={(course) =>
                              navigate(
                                `/courses/${encodeURIComponent(course.slug)}`
                              )
                            }
                          />
                        )
                      )}
                  </Slider>
                ) : (
                  <CoursesRow gutter={32}>
                    {popularCoursesData.fetchPopularCourses
                      .filter((course) => !course.my_activity?.completed)
                      .map(
                        (
                          course: PopularCoursesQuery['fetchPopularCourses'][0]
                        ) => (
                          <CourseCard
                            key={course._id}
                            progress
                            course={course}
                            card
                            likes={ability.can(
                              PermissionAction.READ,
                              PermissionObjectType.LIKE
                            )}
                            pinnable={false}
                            addToCalendar={ability.can(
                              PermissionAction.ADD_TO_CALENDAR,
                              PermissionObjectType.COURSE
                            )}
                            onClick={(course) =>
                              navigate(
                                `/courses/${encodeURIComponent(course.slug)}`
                              )
                            }
                          />
                        )
                      )}
                  </CoursesRow>
                )}
              </>
            )}
          {showPopularSlider && (
            <div style={{ textAlign: 'right', marginTop: -24 }}>
              {
                <SliderArrowLeft
                  disabled={popularSliderCanPrev}
                  onClick={() => popularSliderRef.current?.slickPrev()}
                />
              }
              {
                <SliderArrowRight
                  disabled={popularSliderCanNext}
                  onClick={() => popularSliderRef.current?.slickNext()}
                />
              }
            </div>
          )}
        </Container>
      </Section>
    )
  }

  const AssignedCourses = ({ dark }: { dark: boolean }) => {
    const assignedSliderRef = useRef<Slider>(null)
    const [assignedSliderCurrent, setAssignedSliderCurrent] =
      useState<number>(0)

    const showAssignedSlider = useMemo(() => {
      if (assignedCoursesData?.assignedToMe) {
        if (assignedCoursesData?.assignedToMe.length > 4 && breakpoint.lg)
          return true
        if (assignedCoursesData?.assignedToMe.length > 2 && !breakpoint.lg)
          return true
        return !!(
          assignedCoursesData?.assignedToMe.length > 1 && !breakpoint.sm
        )
      } else {
        return false
      }
    }, [breakpoint, assignedCoursesData])

    const assignedSliderCanPrev = useMemo(() => {
      return assignedSliderCurrent > 0 ? false : true
    }, [assignedSliderCurrent])

    const assignedSliderCanNext = useMemo(() => {
      const visibleSlides = breakpoint.lg ? 4 : breakpoint.md ? 2 : 1
      if (!assignedCoursesData?.assignedToMe) return true
      return assignedSliderCurrent >=
        assignedCoursesData?.assignedToMe.filter(
          (course) => !course.my_activity?.completed
        ).length -
          visibleSlides
        ? true
        : false
    }, [assignedSliderCurrent, assignedCoursesData, breakpoint])

    return (
      <Section dark={dark}>
        <Container>
          <SectionTitleWrapper>
            <SectionTitle>
              <Trans id="dashboard.assigned.title">
                Aan jou toegewezen opleidingen
              </Trans>
            </SectionTitle>
            <Button
              onClick={() => navigate('/assigned')}
              type="text"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <Trans id="dashboard.assigned.see_all">
                Bekijk het volledige overzicht
              </Trans>
            </Button>
          </SectionTitleWrapper>
          <>
            {showAssignedSlider ? (
              // @ts-ignore
              <Slider
                ref={assignedSliderRef}
                beforeChange={(_, currentSlide) =>
                  setAssignedSliderCurrent(currentSlide)
                }
                {...settings}
              >
                {assignedCoursesData?.assignedToMe
                  .filter((course) => !course.my_activity?.completed)
                  .map((course: AssignedCoursesQuery['assignedToMe'][0]) => {
                    return (
                      <CourseCard
                        key={course._id}
                        course={course}
                        card
                        progress
                        cardsPerRow={{
                          sm: 24,
                          lg: 24,
                          xl: 24,
                        }}
                        pinnable={false}
                        likes={ability.can(
                          PermissionAction.READ,
                          PermissionObjectType.LIKE
                        )}
                        addToCalendar={ability.can(
                          PermissionAction.ADD_TO_CALENDAR,
                          PermissionObjectType.COURSE
                        )}
                        assignment={course.assignment}
                        onClick={(course) =>
                          navigate(
                            `/courses/${encodeURIComponent(course.slug)}`
                          )
                        }
                        archived={course.category?.some(
                          (cat) => cat.name === 'Archief'
                        )}
                      />
                    )
                  })}
              </Slider>
            ) : (
              <CoursesRow gutter={32}>
                {assignedCoursesData?.assignedToMe
                  .filter((course) => !course.my_activity?.completed)
                  .map((course: AssignedCoursesQuery['assignedToMe'][0]) => {
                    return (
                      <CourseCard
                        key={course._id}
                        course={course}
                        card
                        progress
                        pinnable={false}
                        likes={ability.can(
                          PermissionAction.READ,
                          PermissionObjectType.LIKE
                        )}
                        addToCalendar={ability.can(
                          PermissionAction.ADD_TO_CALENDAR,
                          PermissionObjectType.COURSE
                        )}
                        assignment={course.assignment}
                        onClick={(course) =>
                          navigate(
                            `/courses/${encodeURIComponent(course.slug)}`
                          )
                        }
                        archived={course.category?.some(
                          (cat) => cat.name === 'Archief'
                        )}
                      />
                    )
                  })}
              </CoursesRow>
            )}
          </>

          {showAssignedSlider && (
            <div style={{ textAlign: 'right', marginTop: -24 }}>
              {
                <SliderArrowLeft
                  disabled={assignedSliderCanPrev}
                  onClick={() => assignedSliderRef.current?.slickPrev()}
                />
              }
              {
                <SliderArrowRight
                  disabled={assignedSliderCanNext}
                  onClick={() => assignedSliderRef.current?.slickNext()}
                />
              }
            </div>
          )}
        </Container>
      </Section>
    )
  }

  const NewCourses = ({ dark }: { dark: boolean }) => {
    const newSliderRef = useRef<Slider>(null)
    const [newSliderCurrent, setNewSliderCurrent] = useState<number>(0)
    const showNewSlider = useMemo(() => {
      if (newCoursesData?.fetchNewCourses) {
        if (newCoursesData?.fetchNewCourses.length > 4 && breakpoint.lg)
          return true
        if (newCoursesData?.fetchNewCourses.length > 2 && !breakpoint.lg)
          return true
        return !!(newCoursesData?.fetchNewCourses.length > 1 && !breakpoint.sm)
      } else {
        return false
      }
    }, [breakpoint, newCoursesData])

    const newSliderCanPrev = useMemo(() => {
      return newSliderCurrent > 0 ? false : true
    }, [newSliderCurrent])

    const newSliderCanNext = useMemo(() => {
      const visibleSlides = breakpoint.lg ? 4 : breakpoint.md ? 2 : 1
      if (!newCoursesData?.fetchNewCourses) return true
      return newSliderCurrent >=
        newCoursesData?.fetchNewCourses.filter(
          (course) => !course.my_activity?.completed
        ).length -
          visibleSlides
        ? true
        : false
    }, [newSliderCurrent, newCoursesData, breakpoint])

    return (
      <Section dark={dark}>
        <Container>
          <SectionTitleWrapper>
            <SectionTitle>
              <Trans id="dashboard.new.title">
                Ontdek hier onze <strong>nieuwste opleidingen</strong>
              </Trans>
            </SectionTitle>
            <Button
              onClick={() => navigate('/courses')}
              type="text"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <>
                <Trans id="dashboard.new.see_all">
                  Bekijk alle opleidingen
                </Trans>
                {courseCount?.fetchCourses.count
                  ? ` (${courseCount?.fetchCourses.count})`
                  : ``}
              </>
            </Button>
          </SectionTitleWrapper>
          {newCoursesData && newCoursesData.fetchNewCourses.length > 0 && (
            <>
              {showNewSlider ? (
                // @ts-ignore
                <Slider
                  ref={newSliderRef}
                  beforeChange={(_, currentSlide) =>
                    setNewSliderCurrent(currentSlide)
                  }
                  {...settings}
                >
                  {newCoursesData.fetchNewCourses
                    .filter((course) => !course.my_activity?.completed)
                    .map((course: NewCoursesQuery['fetchNewCourses'][0]) => (
                      <CourseCard
                        key={course._id}
                        course={course}
                        card
                        progress
                        cardsPerRow={{
                          sm: 24,
                          lg: 24,
                          xl: 24,
                        }}
                        pinnable={false}
                        likes={ability.can(
                          PermissionAction.READ,
                          PermissionObjectType.LIKE
                        )}
                        addToCalendar={ability.can(
                          PermissionAction.ADD_TO_CALENDAR,
                          PermissionObjectType.COURSE
                        )}
                        onClick={(course) =>
                          navigate(
                            `/courses/${encodeURIComponent(course.slug)}`
                          )
                        }
                      />
                    ))}
                </Slider>
              ) : (
                <CoursesRow gutter={32}>
                  {newCoursesData.fetchNewCourses
                    .filter((course) => !course.my_activity?.completed)
                    .map((course: NewCoursesQuery['fetchNewCourses'][0]) => (
                      <CourseCard
                        key={course._id}
                        progress
                        course={course}
                        card
                        pinnable={false}
                        likes={ability.can(
                          PermissionAction.READ,
                          PermissionObjectType.LIKE
                        )}
                        addToCalendar={ability.can(
                          PermissionAction.ADD_TO_CALENDAR,
                          PermissionObjectType.COURSE
                        )}
                        onClick={(course) =>
                          navigate(
                            `/courses/${encodeURIComponent(course.slug)}`
                          )
                        }
                      />
                    ))}
                </CoursesRow>
              )}
            </>
          )}
          {showNewSlider && (
            <div style={{ textAlign: 'right', marginTop: -24 }}>
              {
                <SliderArrowLeft
                  disabled={newSliderCanPrev}
                  onClick={() => newSliderRef.current?.slickPrev()}
                />
              }
              {
                <SliderArrowRight
                  disabled={newSliderCanNext}
                  onClick={() => newSliderRef.current?.slickNext()}
                />
              }
            </div>
          )}
        </Container>
      </Section>
    )
  }

  const sections: (({ dark }: { dark: boolean }) => JSX.Element)[] = []

  if (
    assignedCoursesData &&
    assignedCoursesData.assignedToMe.some(
      (course) => !course.my_activity?.completed
    )
  )
    sections.push(AssignedCourses)

  if (!newCoursesData || newCoursesData?.fetchNewCourses.length > 0)
    sections.push(NewCourses)

  if (!popularCoursesData || popularCoursesData?.fetchPopularCourses.length > 0)
    sections.push(PopularCourses)

  return (
    <>
      <Section>
        <Container>
          <SectionTitle>
            {activeCoursesData && activeCoursesData?.fetchActiveCourses && (
              <>
                <Trans id="dashboard.heading.active_user">
                  Welkom terug, {user?.firstName}.
                </Trans>{' '}
                {activeCoursesData?.fetchActiveCourses?.length > 0 && (
                  <>
                    <strong>
                      <Trans id="dashboard.heading.continue_watching">
                        Kijk verder naar je opleidingen
                      </Trans>
                    </strong>
                    :
                  </>
                )}
                {activeCoursesData?.fetchActiveCourses?.length === 0 && (
                  <>
                    <strong>
                      <Trans id="dashboard.heading.watching_nothing">
                        Je hebt momenteel geen opleidingen gestart.
                      </Trans>
                    </strong>
                  </>
                )}
              </>
            )}
            {activeCoursesData && !activeCoursesData?.fetchActiveCourses && (
              <Trans id="dashboard.heading.new_user">
                Welkom {user?.firstName}, op je persoonlijke leeromgeving.{' '}
                <strong>Veel succes bij het volgen van de opleidingen.</strong>
              </Trans>
            )}
          </SectionTitle>
          {activeCoursesLoading && !activeCoursesData && (
            <CoursesRow gutter={32}>
              <CourseSkeletonCard />
            </CoursesRow>
          )}
          {activeCoursesData &&
            activeCoursesData.fetchActiveCourses &&
            activeCoursesData.fetchActiveCourses?.length > 0 &&
            (showActiveSlider ? (
              // @ts-ignore
              <Slider
                ref={activeSliderRef}
                beforeChange={(_, currentSlide) =>
                  setActiveSliderCurrent(currentSlide)
                }
                {...settings}
                className="courses-light"
              >
                {activeCoursesData.fetchActiveCourses.map((course) => (
                  <CourseCard
                    key={course._id}
                    course={course}
                    progress={true}
                    cardsPerRow={{
                      sm: 24,
                      lg: 24,
                      xl: 24,
                    }}
                    pinnable={false}
                    likes={ability.can(
                      PermissionAction.READ,
                      PermissionObjectType.LIKE
                    )}
                    addToCalendar={ability.can(
                      PermissionAction.ADD_TO_CALENDAR,
                      PermissionObjectType.COURSE
                    )}
                    hide={(_id) =>
                      hideCourseActivity({
                        variables: {
                          course_id: _id,
                        },
                      })
                    }
                    onClick={(course) =>
                      navigate(`/courses/${encodeURIComponent(course.slug)}`)
                    }
                  />
                ))}
              </Slider>
            ) : (
              <CoursesRow gutter={32}>
                {activeCoursesData.fetchActiveCourses.map((course) => (
                  <CourseCard
                    key={course._id}
                    course={course}
                    progress={true}
                    hide={(_id) =>
                      hideCourseActivity({
                        variables: {
                          course_id: _id,
                        },
                      })
                    }
                    pinnable={false}
                    likes={ability.can(
                      PermissionAction.READ,
                      PermissionObjectType.LIKE
                    )}
                    addToCalendar={ability.can(
                      PermissionAction.ADD_TO_CALENDAR,
                      PermissionObjectType.COURSE
                    )}
                    onClick={(course) =>
                      navigate(`/courses/${encodeURIComponent(course.slug)}`)
                    }
                  />
                ))}
              </CoursesRow>
            ))}
          {showActiveSlider && (
            <div style={{ textAlign: 'right', marginTop: -24 }}>
              <SliderArrowLeft
                disabled={activeSliderCanPrev}
                onClick={() => activeSliderRef.current?.slickPrev()}
              />
              <SliderArrowRight
                disabled={activeSliderCanNext}
                onClick={() => activeSliderRef.current?.slickNext()}
              />
            </div>
          )}
        </Container>
      </Section>
      {sections.map((Section, index) => (
        <Section key={index} dark={index % 2 === 0} />
      ))}
    </>
  )
}
