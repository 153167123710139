import { useMutation, useQuery } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import {
  Button,
  Form,
  Input,
  notification,
  PageHeader,
  Space,
  Table,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  AutoTranslateCourseMutation,
  CourseSectionTranslationInput,
  CourseTranslationFieldsQuery,
  TranslateCourseMutation,
  TranslateCourseMutationVariables,
  UnitTranslationInput,
} from 'apps/lms-front/src/generated/graphql'

import { LoadScreen } from '../../../core/components/LoadScreen'
import { RichEditor } from '../../../shared/components/rich-editor/RichEditor'
import { useLanguages } from '../../../shared/hooks/use-languages'

import AUTO_TRANSLATE_COURSE_MUTATION from './../../mutations/auto-translate-course.graphql'
import TRANSLATE_COURSE_MUTATION from './../../mutations/translate-course.graphql'
import COURSE_TRANSLATION_FIELDS_QUERY from './../../queries/course-translation-fields-query.graphql'

type FormConfig = {
  name: string
  label: string
  original?: string
  type?: string
}[]

const courseTranslationFormConfig: FormConfig = [
  {
    name: 'name',
    label: t({
      id: 'course.name',
      message: 'Naam',
    }),
  },
  {
    name: 'short_description',
    label: t({
      id: 'course.short_description',
      message: 'Korte beschrijving',
    }),
    type: 'textarea',
  },
  {
    name: 'description',
    label: t({
      id: 'course.description',
      message: 'Beschrijving',
    }),
    type: 'richtext',
  },
]

export const CourseTranslation = () => {
  const [form] = useForm()
  const [formDirty, setFormDirty] = useState<boolean>(false)

  const { id, lang } = useParams()
  const { data: course, loading } = useQuery<CourseTranslationFieldsQuery>(
    COURSE_TRANSLATION_FIELDS_QUERY,
    {
      variables: {
        id,
      },
      onCompleted: () => {
        form.resetFields()
      },
    }
  )

  const unitsTranslationFormConfig: FormConfig = course
    ? [...course.fetchCourseById.contents]
        .sort((a, b) => a.order - b.order)
        .flatMap(
          (c, cIndex) =>
            c.units
              ?.filter(Boolean)
              .sort((a, b) =>
                'order' in a && 'order' in b ? a.order - b.order : 0
              )
              .map((u, index) => {
                const keys: FormConfig = []

                if (index === 0) {
                  keys.push({
                    original: c.title,
                    name: `contents.${c._id}.title`,
                    label: t({
                      id: 'translation.course.section.title',
                      message: `Sectie #${cIndex + 1}`,
                    }),
                    type: 'text',
                  })
                }

                if (u.__typename === 'VideoUnit') {
                  keys.push({
                    original: u.name,
                    name: `units.${u._id}.name`,
                    label: t({
                      id: 'translation.course.unit.title',
                      message: `Sectie #${cIndex + 1} - Unit #${index + 1}`,
                    }),
                    type: 'text',
                  })
                  if (u.annotation)
                    keys.push({
                      original: u.annotation,
                      name: `units.${u._id}.annotation`,
                      label: t({
                        id: 'translation.course.unit.annotation',
                        message: `Sectie #${cIndex + 1} - Unit #${
                          index + 1
                        } - Annotatie`,
                      }),
                      type: 'richtext',
                    })
                  if (u.questions)
                    u.questions.forEach((q, qIndex) => {
                      keys.push({
                        original: q.question,
                        name: `units.${u._id}.questions.${q._id}.question`,
                        label: t({
                          id: 'translation.course.unit.question',
                          message: `Sectie #${cIndex + 1} - Unit #${
                            index + 1
                          } - Vraag #${qIndex + 1}`,
                        }),
                        type: 'textarea',
                      })
                      q.answers.forEach((a, aIndex) => {
                        keys.push({
                          original: a.answer,
                          name: `units.${u._id}.questions.${q._id}.answers.${aIndex}`,
                          label: t({
                            id: 'translation.course.unit.answer',
                            message: `Sectie #${cIndex + 1} - Unit #${
                              index + 1
                            } - Vraag #${qIndex + 1} - Antwoord #${aIndex + 1}`,
                          }),
                          type: 'text',
                        })
                      }) // answers
                    }) // questions
                }

                if (u.__typename === 'ContentUnit') {
                  keys.push(
                    {
                      original: u.name,
                      name: `units.${u._id}.name`,
                      label: t({
                        id: 'translation.course.unit.title',
                        message: `Sectie #${cIndex + 1} - Unit #${index + 1}`,
                      }),
                      type: 'text',
                    },
                    {
                      original: u.content,
                      name: `units.${u._id}.content`,
                      label: t({
                        id: 'translation.course.unit.content',
                        message: `Sectie #${cIndex + 1} - Unit #${index + 1}`,
                      }),
                      type: 'richtext',
                    }
                  )
                }

                if (u.__typename === 'PDFUnit') {
                  keys.push({
                    original: u.name,
                    name: `units.${u._id}.name`,
                    label: t({
                      id: 'translation.course.unit.title',
                      message: `Sectie #${cIndex + 1} - Unit #${index + 1}`,
                    }),
                    type: 'text',
                  })
                }

                if (u.__typename === 'SurveyUnit') {
                  keys.push({
                    original: u.name,
                    name: `units.${u._id}.name`,
                    label: t({
                      id: 'translation.course.unit.title',
                      message: `Sectie #${cIndex + 1} - Unit #${index + 1}`,
                    }),
                    type: 'text',
                  })
                  u.questions.forEach((q, qIndex) => {
                    keys.push({
                      original: q.question,
                      name: `units.${u._id}.questions.${q._id}.question`,
                      label: t({
                        id: 'translation.course.unit.question',
                        message: `Sectie #${cIndex + 1} - Unit #${
                          index + 1
                        } - Vraag #${qIndex + 1}`,
                      }),
                      type: 'textarea',
                    })
                    q.answers.forEach((a, aIndex) => {
                      keys.push({
                        original: a.answer,
                        name: `units.${u._id}.questions.${q._id}.answers.${aIndex}`,
                        label: t({
                          id: 'translation.course.unit.answer',
                          message: `Sectie #${cIndex + 1} - Unit #${
                            index + 1
                          } - Vraag #${qIndex + 1} - Antwoord #${aIndex + 1}`,
                        }),
                        type: 'text',
                      })
                    }) // answers
                  }) // questions
                }

                if (u.__typename === 'QuizUnit') {
                  keys.push({
                    original: u.name,
                    name: `units.${u._id}.name`,
                    label: t({
                      id: 'translation.course.unit.title',
                      message: `Sectie #${cIndex + 1} - Unit #${index + 1}`,
                    }),
                    type: 'text',
                  })
                  u.questions.forEach((q, qIndex) => {
                    keys.push({
                      original: q.question,
                      name: `units.${u._id}.questions.${q._id}.question`,
                      label: t({
                        id: 'translation.course.unit.question',
                        message: `Sectie #${cIndex + 1} - Unit #${
                          index + 1
                        } - Vraag #${qIndex + 1}`,
                      }),
                      type: 'textarea',
                    })
                    q.answers.forEach((a, aIndex) => {
                      keys.push({
                        original: a.answer,
                        name: `units.${u._id}.questions.${q._id}.answers.${aIndex}`,
                        label: t({
                          id: 'translation.course.unit.answer',
                          message: `Sectie #${cIndex + 1} - Unit #${
                            index + 1
                          } - Vraag #${qIndex + 1} - Antwoord #${aIndex + 1}`,
                        }),
                        type: 'text',
                      })
                    }) // answers
                  }) // questions
                }

                return keys
              })
        )
        .flat()
    : []

  const { languages } = useLanguages()
  const [translate, { loading: translating }] = useMutation<
    TranslateCourseMutation,
    Partial<TranslateCourseMutationVariables>
  >(TRANSLATE_COURSE_MUTATION, {
    variables: {
      course_id: id,
      language: lang,
    },
  })
  const [autoTranslate, { loading: autoTranslating }] =
    useMutation<AutoTranslateCourseMutation>(AUTO_TRANSLATE_COURSE_MUTATION, {
      variables: {
        course_id: id,
        language: lang,
      },
      refetchQueries: ['course'],
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'settings.courses.action.translate.auto.success',
            message: 'Automatische vertaling succesvol opgeslagen',
          }),
        })
        setFormDirty(false)
      },
      onError: () => {
        notification.error({
          message: t({
            id: 'settings.courses.action.translate.auto.error',
            message: 'Er liep iets fout bij het automatisch vertalen',
          }),
        })
      },
    })

  const handleTranslate = async () => {
    const fields = await form.validateFields()
    await translate({
      variables: {
        translation: {
          name: fields.name,
          short_description: fields.short_description,
          description: fields.description,
          contents: Object.keys(fields).reduce<CourseSectionTranslationInput[]>(
            (arr, f) => {
              if (f.startsWith('contents')) {
                const [_, id, field] = f.split('.')
                // If exists, add the property
                if (arr.some((item) => item._id === id)) {
                  arr.find((item) => item._id === id)![field] = fields[f]
                } else {
                  // push a new object
                  arr.push({
                    _id: id,
                    [field]: fields[f],
                  })
                }
              }
              return arr
            },
            []
          ),
          units: Object.keys(fields).reduce<UnitTranslationInput[]>(
            (arr, f) => {
              if (f.startsWith('units') && f.split('.').length === 3) {
                // It's a unit
                const [_, id, field] = f.split('.')
                if (arr.some((item) => item._id === id)) {
                  arr.find((item) => item._id === id)![field] = fields[f]
                } else {
                  // push a new object
                  arr.push({
                    _id: id,
                    [field]: fields[f],
                  })
                }
              } else if (f.startsWith('units') && f.split('.').length === 5) {
                const [_, id, field, question_id, question_field] = f.split('.')
                if (arr.some((item) => item._id === id)) {
                  const existing = arr.find((item) => item._id === id)
                  if (existing![field]) {
                    existing![field] = [
                      ...existing![field],
                      {
                        _id: question_id,
                        [question_field]: fields[f],
                      },
                    ]
                  } else {
                    existing![field] = [
                      {
                        _id: question_id,
                        [question_field]: fields[f],
                      },
                    ]
                  }
                } else {
                  // push a new object
                  arr.push({
                    _id: id,
                    [field]: [
                      {
                        _id: question_id,
                        [question_field]: fields[f],
                      },
                    ],
                  })
                }
              } else if (f.startsWith('units') && f.split('.').length === 6) {
                const [_, id, field, question_id, answer_field, answer_index] =
                  f.split('.')

                if (arr.some((item) => item._id === id)) {
                  const existing = arr.find((item) => item._id === id)
                  if (existing![field]) {
                    existing![field] = existing![field].map((q) => {
                      if (q._id === question_id) {
                        q[answer_field] = q[answer_field]
                          ? [
                              ...q[answer_field],
                              {
                                _id: answer_index,
                                answer: fields[f],
                              },
                            ]
                          : [
                              {
                                _id: answer_index,
                                answer: fields[f],
                              },
                            ]
                      }
                      return q
                    })
                  } else {
                    existing![field] = [
                      {
                        _id: question_id,
                        [answer_field]: [
                          {
                            _id: answer_index,
                            answer: fields[f],
                          },
                        ],
                      },
                    ]
                  }
                } else {
                  // push a new object
                  arr.push({
                    _id: id,
                    [field]: [
                      {
                        _id: question_id,
                        [answer_field]: [
                          {
                            _id: answer_index,
                            answer: fields[f],
                          },
                        ],
                      },
                    ],
                  })
                }
              }
              return arr
            },
            []
          ),
        },
      },
    })
      .then(() => {
        notification.success({
          message: t({
            id: 'settings.courses.action.translate.success',
            message: 'Vertaling succesvol opgeslagen',
          }),
        })
        setFormDirty(false)
      })
      .catch((error) => {
        notification.error({
          message: t({
            id: 'settings.courses.action.translate.error',
            message: 'Er liep iets fout bij het opslaan van de vertaling',
          }),
        })
        console.error(error)
      })
  }

  const translation = course?.fetchCourseById.translations?.find(
    (t) => t.language === lang
  )

  useEffect(() => {
    form.resetFields()
  }, [form, translation])

  const unitTranslateableFields = useMemo(
    () => ({
      ContentUnit: ['name', 'content'],
      VideoUnit: ['name', 'annotation'],
      PDFUnit: ['name'],
      QuizUnit: ['name'],
      SurveyUnit: ['name'],
    }),
    []
  )

  const initialValues = useMemo(() => {
    if (!course) return {}
    return {
      ...translation,
      ...Object.fromEntries(
        course.fetchCourseById.contents.map((c) => [
          `contents.${c._id}.title`,
          c.translations?.find((t) => t.language === lang)?.title || '',
        ])
      ),
      ...Object.fromEntries(
        course.fetchCourseById.contents.flatMap((c) => {
          const units = c.units.map((u) =>
            '_id' in u &&
            'translations' in u &&
            u.__typename &&
            u.__typename in unitTranslateableFields
              ? unitTranslateableFields[u.__typename].map((field) => {
                  const translation = u.translations?.find(
                    (t) => t.language === lang
                  )
                  const key = `units.${u._id}.${field}`
                  const value = translation?.[field] || ''
                  return [key, value]
                })
              : []
          )
          return units.flat()
        })
      ),
      ...Object.fromEntries(
        course.fetchCourseById.contents.flatMap((c) => {
          return c.units.flatMap((u) => {
            if (u.__typename === 'VideoUnit') {
              return u.questions.flatMap((q) => {
                const t = q.translations?.find((t) => t.language === lang)
                return [
                  [`units.${u._id}.questions.${q._id}.question`, t?.question],
                  [
                    `units.${u._id}.questions.${q._id}.explanation`,
                    t?.explanation,
                  ],
                  ...q.answers.map((a, aIndex) => {
                    const t = a.translations?.find((t) => t.language === lang)
                    return [
                      `units.${u._id}.questions.${q._id}.answers.${aIndex}`,
                      t?.answer,
                    ]
                  }),
                ].filter((i) => i[1])
              })
            }

            if (u.__typename === 'QuizUnit') {
              return u.questions.flatMap((q) => {
                const t = q.translations?.find((t) => t.language === lang)
                return [
                  [`units.${u._id}.questions.${q._id}.question`, t?.question],
                  [
                    `units.${u._id}.questions.${q._id}.explanation`,
                    q.explanation,
                  ],
                  ...q.answers.map((a, aIndex) => {
                    const t = a.translations?.find((t) => t.language === lang)
                    return [
                      `units.${u._id}.questions.${q._id}.answers.${aIndex}`,
                      t?.answer,
                    ]
                  }),
                ].filter((i) => i[1])
              })
            }

            if (u.__typename === 'SurveyUnit') {
              return u.questions.flatMap((q) => {
                const t = q.translations?.find((t) => t.language === lang)
                return [
                  [`units.${u._id}.questions.${q._id}.question`, t?.question],
                  ...q.answers.map((a, aIndex) => {
                    const t = a.translations?.find((t) => t.language === lang)
                    return [
                      `units.${u._id}.questions.${q._id}.answers.${aIndex}`,
                      t?.answer,
                    ]
                  }),
                ].filter((i) => i[1])
              })
            }

            return []
          })
        })
      ),
    }
  }, [course, lang, translation, unitTranslateableFields])

  if (!course || loading) return <LoadScreen />

  const columns = [
    {
      title: t({
        id: 'settings.courses.table.field',
        message: 'Veld',
      }),
      dataIndex: 'field',
      key: 'field',
      width: '20%',
    },
    {
      title:
        languages.find((l) => course.fetchCourseById.language === l.code)
          ?.name ||
        t({
          id: 'translation.original',
          message: 'Origineel',
        }),
      dataIndex: 'original',
      key: 'original',
      width: '40%',
      render: (field, record) => {
        if (record.type === 'richtext') {
          return <div dangerouslySetInnerHTML={{ __html: field }} />
        }
        return field
      },
    },
    {
      title: languages.find((l) => lang === l.code)?.name,
      dataIndex: 'translation',
      key: 'translation',
      render: (field, record) => {
        if (record.type === 'textarea') {
          return (
            <Form.Item key={field} style={{ marginBottom: 0 }} name={field}>
              <Input.TextArea />
            </Form.Item>
          )
        }
        if (record.type === 'richtext') {
          return (
            <Form.Item key={field} style={{ marginBottom: 0 }} name={field}>
              <RichEditor
                disableTextStyles
                onChange={(value) => {
                  form.setFieldsValue({ [field]: value })
                  setFormDirty(true)
                }}
              />
            </Form.Item>
          )
        }
        return (
          <Form.Item key={field} style={{ marginBottom: 0 }} name={field}>
            <Input />
          </Form.Item>
        )
      },
      width: '40%',
    },
  ]

  const data = [
    ...courseTranslationFormConfig,
    ...unitsTranslationFormConfig,
  ].map((field) => ({
    key: field.name,
    field: field.label,
    original: field.original || course.fetchCourseById[field.name],
    translation: field.name,
    type: field.type,
  }))

  return (
    <Form
      disabled={translating || autoTranslating}
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleTranslate}
      autoComplete="off"
      initialValues={initialValues}
      onChange={() => setFormDirty(true)}
    >
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={
          <Trans id="settings.courses.title.translate">
            Vertaling van &ldquo;{course.fetchCourseById.name}&rdquo; naar
            &ldquo;
            {languages.find((l) => lang === l.code)?.name}&rdquo;
          </Trans>
        }
        extra={
          <Space>
            <Button
              onClick={() => {
                window.history.back()
              }}
            >
              <Trans id="actions.go_back">Ga terug</Trans>
            </Button>
            <Button
              loading={autoTranslating}
              onClick={() => {
                autoTranslate()
              }}
            >
              <Trans id="actions.auto_translate">Automatisch vertalen</Trans>
            </Button>
            <Button
              loading={translating}
              disabled={!formDirty}
              type="primary"
              htmlType={'submit'}
            >
              <Trans id="actions.save">Opslaan</Trans>
            </Button>
          </Space>
        }
      />
      <Table
        className="translation-table"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </Form>
  )
}
