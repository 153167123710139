/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable unicorn/no-useless-undefined */
import {
  DeleteOutlined,
  EditOutlined,
  BarChartOutlined,
  DownOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Checkbox,
  Button,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Select,
  Table,
  Tag,
  CheckboxOptionType,
  Tooltip,
  Alert,
  Divider,
  Space,
  message,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import { ColumnsType } from 'antd/lib/table'
import { ObjectId } from 'bson'
import dayjs, { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { groupBy } from 'lodash-es'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import {
  PermissionAction,
  PermissionObjectType,
  subject,
} from '@lms-shared-patterns/models'
import {
  CreateUserMutation,
  UpdateUserMutation,
  DeleteUserMutation,
  CertificationTypesQuery,
  BranchUsersQuery,
  FieldsQuery,
  FieldParent,
  ChannelsQuery,
  LanguagesQuery,
} from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { AbilityContext, Can } from '../../../auth/components/Can'
import { setSessionStorageItem } from '../../../core/utils/session-storage'
import { exporter } from '../../../reports/helpers/export'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { CustomFieldsFormSection } from '../../../shared/components/custom-fields-form-section/CustomFieldsFormSection'
import { DropdownButton } from '../../../shared/components/dynamic-dropdown-button/DropdownButton'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { TreeSelect } from '../../../shared/components/tree-select/TreeSelect'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { reduceDuplicateLabels } from '../../../shared/helpers/reduce-duplicate-labels'
import { sameHundredRange } from '../../../shared/helpers/same-hundred-range'
import { useHierarchyTree } from '../../hooks/use-hierarchy-tree'

import CREATE_USER_MUTATION from './../../../settings/mutations/create-user.graphql'
import DELETE_USER_MUTATION from './../../../settings/mutations/delete-user.graphql'
import UPDATE_USER_MUTATION from './../../../settings/mutations/update-user.graphql'
import BRANCH_USERS_QUERY from './../../../settings/queries/branch-users.graphql'
import CERTIFICATION_TYPES_QUERY from './../../../settings/queries/certification-types.graphql'
import CHANNELS_QUERY from './../../../settings/queries/channels.graphql'
import FIELDS_QUERY from './../../../settings/queries/fields.graphql'
import LANGUAGES_QUERY from './../../../settings/queries/languages.graphql'

extend(utc)
extend(timezone)
extend(relativeTime)

interface UserRow {
  key: string
  name: string
  email: string
  created?: Date
  lastActive?: Date
  disabled?: boolean | null
  channels: BranchUsersQuery['fetchBranchUsers']['results'][0]['channels']
  certificationType: BranchUsersQuery['fetchBranchUsers']['results'][0]['certificationType']
  language?: string | null
}

export const BranchUsers = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const { data: languages } = useQuery<LanguagesQuery>(LANGUAGES_QUERY)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [searchTerm, setSearchTerm] = useQueryParam('q', StringParam)
  const [hierarchyFilter, setHierarchyFilter] = useQueryParam(
    'section',
    StringParam
  )
  const [sortField, setSortField] = useState<string>()
  const [sortOrder, setSortOrder] = useState<number>()
  const [userModalVisible, setUserModalVisible] = useState(false)
  const [deletePopConfirmIndex, setDeletePopConfirmIndex] = useState<string>()
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false)
  const [updateSubject, setUpdateSubject] =
    useState<BranchUsersQuery['fetchBranchUsers']['results'][0]>()
  const [form] = useForm()
  const disabled = Form.useWatch('disabled', form)
  const selectedChannels = Form.useWatch('channels', form)

  const branch = useBranch()
  const ability = useContext(AbilityContext)

  const { data, loading } = useQuery<BranchUsersQuery>(BRANCH_USERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: searchTerm,
      section_id: hierarchyFilter || undefined,
      page,
      limit: pageSize,
      sort_by: sortField,
      sort_order: sortOrder,
    },
  })

  const showLanguages = useMemo(() => {
    return branch?.languages ? branch?.languages.length > 1 : false
  }, [branch])

  const showTenants = useMemo(() => {
    return data?.fetchBranchUsers?.results.some((u) => u.meta?.tenant_id)
  }, [data])

  const hasTenant = useMemo(() => {
    return updateSubject?.meta?.tenant_id
  }, [updateSubject])

  const { data: treeData, loading: treeLoading } = useHierarchyTree({
    filterByPermission: {
      action: PermissionAction.READ,
      object: PermissionObjectType.BRANCH_USER,
    },
    noBranches: true,
  })

  const { data: certificationTypes } = useQuery<CertificationTypesQuery>(
    CERTIFICATION_TYPES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      skip: ability.cannot(
        PermissionAction.READ,
        PermissionObjectType.CERTIFICATION_TYPE
      ),
    }
  )

  const { data: channels } = useQuery<ChannelsQuery>(CHANNELS_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: ability.cannot(PermissionAction.READ, PermissionObjectType.CHANNEL),
  })

  const { data: fields } = useQuery<FieldsQuery>(FIELDS_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const channelOptions = useMemo(() => {
    return channels?.fetchChannels
      .filter(
        (c) =>
          branch?.channels.enabled.some((e) => new ObjectId(e).equals(c._id))
      )
      .map(
        (type) =>
          ({
            label: type.name,
            value: type._id as string,
          }) as CheckboxOptionType
      )
  }, [channels, branch])

  const [createUser, { loading: creating }] =
    useMutation<CreateUserMutation>(CREATE_USER_MUTATION)

  const [updateUser, { loading: updating }] =
    useMutation<UpdateUserMutation>(UPDATE_USER_MUTATION)

  const [deleteUser, { loading: deleting }] =
    useMutation<DeleteUserMutation>(DELETE_USER_MUTATION)

  useEffect(() => {
    if (updateSubject) {
      setUserModalVisible(true)
    }
  }, [updateSubject])

  useEffect(() => {
    if (userModalVisible) form.resetFields()
  }, [userModalVisible, form])

  const selectedUser = useMemo(() => {
    if (!updateSubject) return
    let role
    const _branch = updateSubject?.branches?.find((b) =>
      new ObjectId(b.branch_id).equals(branch?._id)
    )
    if (_branch) role = _branch.role_id
    return {
      ...updateSubject,
      role,
    }
  }, [updateSubject, branch?._id])

  const userRoleOptions = useMemo(() => {
    return Object.values(
      groupBy(
        data?.fetchBranchRoles
          .filter((role) => {
            const assignedRoleIds = selectedUser?.branches
              ?.filter((b) => new ObjectId(b.branch_id).equals(branch?._id))
              .map((b) => b.role_id)
            return (
              assignedRoleIds?.some((role_id) =>
                new ObjectId(role._id).equals(role_id || '')
              ) ||
              (sameHundredRange(role.level, auth.user?.role_level ?? 9999) &&
                role.level >= (auth.user?.role_level ?? 9999)) ||
              auth.user?.role_level === 0
            )
          })
          .map((role) => ({
            ...role,
            name: role.name.split(' (')[0],
            branch_name:
              role.branch_name === ''
                ? role.level >= 100
                  ? t({
                      id: 'settings.users.form.label.pro',
                      message: 'Pro',
                    })
                  : t({
                      id: 'settings.users.form.label.default',
                      message: 'Standaard',
                    })
                : role.branch_name,
          })),
        ({ branch_name }) => branch_name
      )
    ).map((roles) => {
      const branch =
        roles[0].branch_name ||
        t({
          id: 'settings.users.form.label.default',
          message: 'Standaard',
        })
      return {
        label: branch,
        options: roles.map((userRole) => ({
          value: userRole._id,
          label: userRole.name,
          disabled: userRole.level < (auth.user?.role_level ?? 9999),
        })),
      }
    })
  }, [data, branch, auth.user, selectedUser])

  const users = useMemo(() => {
    return (data?.fetchBranchUsers.results || []).map<UserRow>(
      (item: BranchUsersQuery['fetchBranchUsers']['results'][0]) => {
        return {
          key: item._id,
          name: `${item.firstName} ${item.lastName}`,
          email: item.email,
          created: item.created,
          lastActive: item.lastActive,
          disabled: item.disabled,
          certificationType: item.certificationType,
          channels: item.channels,
          branches: item.branches,
          tenant: item.meta?.tenant_id,
          language: item?.lang,
        }
      }
    )
  }, [data])

  const columns: ColumnsType<UserRow> = [
    {
      title: t({
        id: 'branch.users.table.name',
        message: 'Naam',
      }),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      fixed: 'left',
      render: (text: string, item: UserRow) => (
        <Space>
          {text}
          {item.certificationType
            ?.reduce<{ _id: string; name: string }[]>(reduceDuplicateLabels, [])
            .map((type) => (
              <Tag key={type._id} color="blue">
                {type.name}
              </Tag>
            ))}
          {item.disabled && (
            <Tag color="red">
              <Trans id="branch.users.tag.disabled">Inactief</Trans>
            </Tag>
          )}
        </Space>
      ),
    },
    {
      title: t({
        id: 'branch.users.table.email',
        message: 'E-mailadres',
      }),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      ellipsis: true,
    },
    {
      title: t({
        id: 'branch.users.table.created',
        message: 'Registratie',
      }),
      dataIndex: 'created',
      key: 'created',
      width: 125,
      sorter: true,
      render: (text: string) =>
        dayjs(text).tz('Europe/Brussels').format('DD/MM/YYYY'),
      // render: (text: string) => dayjs(text).tz('Europe/Brussels').fromNow(),
    },
    {
      title: t({
        id: 'branch.users.table.last_active',
        message: 'Laatst actief',
      }),
      dataIndex: 'lastActive',
      key: 'lastActive',
      width: 225,
      sorter: true,
      render: (text: string) =>
        text && dayjs(text).tz('Europe/Brussels').fromNow(),
    },
    showLanguages && {
      title: t({
        id: 'branch.users.table.language',
        message: 'Language',
      }),
      key: 'lang',
      dataIndex: 'lang',
      width: 100,
      sorter: true,
      render: (_: string, item: UserRow) => {
        if (item?.language?.includes('en')) return 'EN'
        if (item?.language?.includes('fr')) return 'FR'
        if (item?.language?.includes('nl')) return 'NL'
        return '-'
      },
    },
    showTenants && {
      title: t({
        id: 'branch.users.table.tenant',
        message: 'Tenant',
      }),
      key: 'tenant',
      dataIndex: 'tenant',
      width: 100,
      render: (val: string) =>
        (val &&
          branch?.scimTenants?.find((t) => t.tenant_id === val)?.tenant_name) ||
        '-',
    },
    {
      title: t({
        id: 'branch.users.table.action',
        message: 'Acties',
      }),
      key: 'operation',
      fixed: 'right',
      width: 160,
      render: (_: string, record: UserRow) => (
        <ActionButtonWrapper>
          <Can
            I={PermissionAction.READ}
            a={subject(PermissionObjectType.BRANCH_REPORT, record)}
          >
            <Tooltip
              title={t({
                id: 'branch.users.tooltip.reports',
                message: 'Rapporten',
              })}
            >
              <Button
                onClick={() => {
                  sessionStorage.removeItem('aa_report_filter')
                  navigate(
                    `/reports/users?query=${encodeURIComponent(
                      record.name
                    )}&expand=${record.key}`
                  )
                }}
                shape="circle"
                icon={<BarChartOutlined />}
              />
            </Tooltip>
          </Can>
          <Can
            I={PermissionAction.UPDATE}
            a={subject(PermissionObjectType.BRANCH_USER, record)}
          >
            <Tooltip
              title={t({
                id: 'action.edit',
                message: 'Bewerken',
              })}
            >
              <Button
                onClick={() => {
                  setUpdateSubject(
                    data?.fetchBranchUsers.results.find((user) =>
                      new ObjectId(user._id).equals(record.key)
                    )
                  )
                }}
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Can>
          <Can
            I={PermissionAction.DELETE}
            a={subject(PermissionObjectType.BRANCH_USER, record)}
          >
            <Tooltip
              title={t({
                id: 'action.delete',
                message: 'Verwijderen',
              })}
            >
              <Popconfirm
                placement={'left'}
                title={t({
                  id: 'branch.users.delete.title',
                  message:
                    'Ben je zeker dat je deze gebruiker wil verwijderen?',
                })}
                open={
                  deletePopConfirmIndex === record.key &&
                  deletePopConfirmVisible
                }
                okType="danger"
                okText={t({
                  id: 'action.delete',
                  message: 'Verwijderen',
                })}
                cancelText={t({
                  id: 'action.cancel',
                  message: 'Annuleren',
                })}
                okButtonProps={{ loading: deleting }}
                onConfirm={() => {
                  deleteUser({
                    variables: {
                      id: record.key,
                    },
                    refetchQueries: ['branchUsers'],
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'branch.users.delete.success',
                          message: 'Gebruiker succesvol verwijderd',
                        }),
                      })
                    })
                    .catch(errorNotifierFn)
                    .finally(() => setDeletePopConfirmVisible(false))
                }}
                onCancel={() => setDeletePopConfirmVisible(false)}
              >
                <Button
                  onClick={() => {
                    setDeletePopConfirmIndex(record.key)
                    setDeletePopConfirmVisible(true)
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          </Can>
        </ActionButtonWrapper>
      ),
    },
  ].filter(Boolean) as ColumnsType<UserRow>

  const processBranches = (branches) => {
    return (
      branches
        ?.map((branch) => {
          const section = treeData.find((node) =>
            new ObjectId(node.id).equals(branch.section_id)
          )
          if (!section) return null
          return {
            branch_id: section.branch_id,
            section_id:
              section.branch_id === section.id ? undefined : section.id,
            role_id: branch.role_id || null,
          }
        })
        .filter(Boolean) || []
    )
  }

  const handleCreation = async () => {
    try {
      const { role, branches, ...fields } = await form.validateFields()
      if (
        !branches ||
        branches.filter((b) => b.role_id || b.section_id).length === 0
      ) {
        throw new Error(
          t({
            id: 'branch.users.form.validation.branches',
            message: 'Gelieve minstens één afdeling toe te wijzen',
          })
        )
      }
      await createUser({
        variables: {
          ...fields,
          role: role || (branches ? undefined : null),
          branches: processBranches(branches),
        },
        refetchQueries: ['branchUsers'],
      })
      notification.success({
        message: t({
          id: 'branch.users.create.success',
          message: 'Gebruiker succesvol aangemaakt',
        }),
      })
      setUserModalVisible(false)
    } catch (error: unknown) {
      console.error(error)
      if (error instanceof Error) {
        notification.error({
          message: error.message,
        })
      } else if ('errorFields' in (error as Record<string, unknown>)) {
        notification.error({
          message: t({
            id: 'branch.users.form.validation.error',
            message: 'Gelieve alle verplichte velden in te vullen',
          }),
        })
      } else {
        notification.error({
          message: t({
            id: 'error.general',
            message: 'Er is een fout opgetreden. Probeer later opnieuw.',
          }),
        })
      }
    }
  }

  const handleUpdate = async () => {
    try {
      const { role, branches, ...fields } = await form.validateFields()

      if (
        !branches ||
        branches.filter((b) => b.role_id || b.section_id).length === 0
      ) {
        throw new Error(
          t({
            id: 'branch.users.form.validation.branches',
            message: 'Gelieve minstens één afdeling toe te wijzen',
          })
        )
      }
      const processedBranches = [
        ...processBranches(branches),
        ...(selectedUser?.branches
          ?.filter((b) => !new ObjectId(b.branch_id).equals(branch?._id))
          .map((s) => ({
            branch_id: s.branch_id,
            section_id: s.section_id,
            role_id: s.role_id,
          })) || []),
      ]

      await updateUser({
        variables: {
          id: updateSubject?._id,
          ...fields,
          role: role || (branches ? undefined : null),
          branches: processedBranches,
        },
        refetchQueries: ['branchUsers'],
      })

      notification.success({
        message: t({
          id: 'branch.users.update.success',
          message: 'Gebruiker succesvol gewijzigd',
        }),
      })

      setUserModalVisible(false)
    } catch (error) {
      if (error instanceof Error) {
        notification.error({
          message: error.message,
        })
      } else if ('errorFields' in (error as Record<string, unknown>)) {
        notification.error({
          message: t({
            id: 'branch.users.form.validation.error',
            message: 'Gelieve alle verplichte velden in te vullen',
          }),
        })
      } else {
        notification.error({
          message: t({
            id: 'error.general',
            message: 'Er is een fout opgetreden. Probeer later opnieuw.',
          }),
        })
      }
    }
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={
          <>
            {t({
              id: 'branch.users.heading',
              message: 'Gebruikers',
            })}{' '}
            {data?.fetchBranchUsers.count
              ? `(${data?.fetchBranchUsers.count})`
              : ''}
          </>
        }
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <InputSearch
            key="1"
            defaultValue={searchTerm || ''}
            placeholder={t({
              id: 'branch.users.search',
              message: 'Zoeken op naam',
            })}
            onSearch={(value) => {
              setSearchTerm(value)
              setPage(1)
            }}
            style={{ width: 200 }}
            loading={loading}
          />,
          ability.can(
            PermissionAction.READ,
            PermissionObjectType.BRANCH_HIERARCHY
          ) && (
            <TreeSelect
              key="2"
              placeholder={t({
                id: 'branch.users.filter.hierarchy',
                message: 'Filter op afdeling',
              })}
              treeDefaultExpandAll={true}
              treeLine={true}
              showSearch
              treeDataSimpleMode
              style={{ width: 250 }}
              dropdownMatchSelectWidth={false}
              filterTreeNode={(input, option) =>
                (option.title as string)
                  ?.toLowerCase()
                  .includes(input.toLowerCase())
              }
              dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
              }}
              treeData={treeData}
              loading={treeLoading}
              allowClear={true}
              treeNodeLabelProp="label"
              value={hierarchyFilter}
              onChange={(value) => {
                setSessionStorageItem('aa_report_filter', value)
                setHierarchyFilter(value)
                setPage(1)
              }}
            />
          ),
          <Can
            key="3"
            I={PermissionAction.CREATE}
            a={PermissionObjectType.BRANCH_USER}
          >
            <DropdownButton
              icon={<DownOutlined />}
              onClick={() => setUserModalVisible(true)}
              type="primary"
              menu={{
                hidden: !(
                  ability.can(
                    PermissionAction.IMPORT,
                    PermissionObjectType.BRANCH_USER
                  ) ||
                  ability.can(
                    PermissionAction.EXPORT,
                    PermissionObjectType.BRANCH_USER
                  )
                ),
                onClick: ({ key }) => {
                  if (key === 'export-branch-users') {
                    const hideExportLoader = message.loading(
                      t({
                        id: 'export.preparing',
                      }),
                      0
                    )
                    exporter({
                      name: 'user-export',
                      url: '/api/export/branch-users',
                      type: 'xlsx',
                      params: {
                        section_id: hierarchyFilter || undefined,
                      },
                      dates: ['created', 'updated', 'lastLogin', 'lastActive'],
                    })
                      .then(() => {
                        message.success(
                          t({
                            id: 'export.ready',
                            message: 'Data klaar voor export!',
                          }),
                          1
                        )
                      })
                      .finally(hideExportLoader)
                  } else {
                    navigate(`/settings/${key}`)
                  }
                },
                items: [
                  ability.can(
                    PermissionAction.IMPORT,
                    PermissionObjectType.BRANCH_USER
                  )
                    ? {
                        key: 'import-branch-users',
                        label: t({
                          id: 'branch.users.import',
                          message: 'Gebruikers importeren',
                        }),
                      }
                    : null,
                  ability.can(
                    PermissionAction.EXPORT,
                    PermissionObjectType.BRANCH_USER
                  )
                    ? {
                        key: 'export-branch-users',
                        label: t({
                          id: 'branch.users.export',
                          message: 'Gebruikers exporteren',
                        }),
                      }
                    : null,
                ],
              }}
            >
              <Trans id="branch.users.create">Gebruiker aanmaken</Trans>
            </DropdownButton>
          </Can>,
        ]}
      />
      <Table
        locale={{
          emptyText: t({
            id: 'branch.users.table.empty',
            message: 'Geen gebruikers gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        scroll={{ x: 400 }}
        dataSource={users}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        onChange={(_, __, sorterInfo) => {
          if (!('length' in sorterInfo)) {
            setSortField(String(sorterInfo.field))
            setSortOrder(
              sorterInfo.order === 'ascend'
                ? 1
                : sorterInfo.order === 'descend'
                ? -1
                : undefined
            )
          }
        }}
        pagination={{
          current: page,
          onChange: (page: number) => {
            setPage(page)
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0
          },
          onShowSizeChange(_, size) {
            setPage(1)
            setPageSize(size)
          },
          total: data?.fetchBranchUsers.count,
        }}
      />
      <Modal
        forceRender
        title={
          updateSubject
            ? t({
                id: 'branch.users.update.title',
                message: 'Gebruiker wijzigen',
              })
            : t({
                id: 'branch.users.create.title',
                message: 'Nieuwe gebruiker aanmaken',
              })
        }
        open={userModalVisible}
        onOk={updateSubject ? handleUpdate : handleCreation}
        confirmLoading={creating || updating}
        onCancel={() => {
          setUserModalVisible(false)
        }}
        afterClose={() => setUpdateSubject(undefined)}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okText={
          updateSubject
            ? t({
                id: 'action.update',
                message: 'Wijzigen',
              })
            : t({
                id: 'action.create',
                message: 'Aanmaken',
              })
        }
        width={640}
      >
        <Space direction="vertical" size={'large'}>
          {hasTenant && (
            <Alert
              type="info"
              showIcon={true}
              message={
                <Trans id="branch.users.external.warning">
                  Deze gebruiker wordt extern beheerd en gesynchroniseerd via
                  SCIM.
                </Trans>
              }
            />
          )}
          <Form
            key={updateSubject?._id}
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={updateSubject ? handleUpdate : handleCreation}
            initialValues={
              selectedUser
                ? {
                    ...selectedUser,
                    branches: selectedUser.branches
                      ?.filter((b) =>
                        new ObjectId(b.branch_id).equals(branch?._id)
                      )
                      .map((branch) => {
                        return {
                          section_id: branch.section_id || branch.branch_id,
                          role_id: branch.role_id,
                        }
                      }),
                    channels: selectedUser?.channels?.map((c) => c._id),
                    certificationType: selectedUser?.certificationType?.map(
                      (type) => type._id
                    ),
                  }
                : {
                    branches: [
                      {
                        section_id: null,
                        role_id: null,
                      },
                    ],
                    channels: branch?.channels.default,
                  }
            }
            autoComplete="off"
          >
            <Form.Item
              label={t({
                id: 'branch.users.form.label.first_name',
                message: 'Voornaam',
              })}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: t({
                    id: 'branch.users.form.validation.first_name',
                    message: 'Gelieve de voornaam in te vullen',
                  }),
                },
              ]}
            >
              <Input autoComplete="off" disabled={hasTenant} />
            </Form.Item>
            <Form.Item
              label={t({
                id: 'branch.users.form.label.last_name',
                message: 'Naam',
              })}
              name="lastName"
              rules={[
                {
                  required: true,
                  message: t({
                    id: 'branch.users.form.validation.last_name',
                    message: 'Gelieve de naam in te vullen',
                  }),
                },
              ]}
            >
              <Input autoComplete="off" disabled={hasTenant} />
            </Form.Item>
            <Form.Item
              label={t({
                id: 'branch.users.form.label.email',
                message: 'E-mailadres',
              })}
              name="email"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: t({
                    id: 'branch.users.form.validation.email',
                    message: 'Gelieve een geldig e-mailadres in te vullen',
                  }),
                },
              ]}
            >
              <Input autoComplete="off" disabled={hasTenant} />
            </Form.Item>

            {!(hasTenant && !branch?.loginMethods.includes('EmailPass')) && (
              <Form.Item
                label={t({
                  id: 'branch.users.form.label.password',
                  message: 'Wachtwoord',
                })}
                name="password"
              >
                <Input.Password
                  placeholder={
                    updateSubject
                      ? t({
                          id: 'branch.users.form.placeholder.password',
                          message: 'Laat leeg om niet te wijzigen',
                        })
                      : t({
                          id: 'branch.users.form.placeholder.password.new',
                          message:
                            'Laat leeg om een veilig wachtwoord te genereren',
                        })
                  }
                  autoComplete="new-password"
                />
              </Form.Item>
            )}

            <Can I={PermissionAction.ASSIGN} a={PermissionObjectType.ROLE}>
              <Can
                I={PermissionAction.UPDATE}
                a={PermissionObjectType.BRANCH_USER}
              >
                <Form.List
                  name="branches"
                  rules={[
                    {
                      validator: async (_, branches) => {
                        if (!branches || branches.length === 0) {
                          throw new Error(
                            t({
                              id: 'branch.users.form.validation.branches',
                              message:
                                'Gelieve minstens één afdeling toe te wijzen',
                            })
                          )
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: 24,
                            paddingBottom: 24,
                            borderTop: '1px solid #f0f0f0',
                          }}
                          key={key}
                        >
                          <Space
                            size={0}
                            style={{ width: '100%' }}
                            direction="vertical"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, 'section_id']}
                              label={t({
                                id: 'settings.users.form.label.branch',
                                message: 'Afdeling',
                              })}
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              style={{ flex: 1 }}
                              required
                            >
                              <TreeSelect
                                treeDefaultExpandAll={true}
                                treeLine={true}
                                showSearch
                                treeDataSimpleMode
                                style={{ width: '100%' }}
                                dropdownMatchSelectWidth={false}
                                filterTreeNode={(input, option) =>
                                  (option.title as string)
                                    ?.toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                dropdownStyle={{
                                  maxHeight: 400,
                                  overflow: 'auto',
                                }}
                                treeData={treeData}
                                loading={treeLoading}
                                allowClear={false}
                                treeNodeLabelProp="label"
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'role_id']}
                              label={t({
                                id: 'settings.users.form.label.role',
                                message: 'Gebruikersrol',
                              })}
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              style={{ flex: 1, margin: 0 }}
                            >
                              <Select
                                options={[...userRoleOptions].sort((a, b) => {
                                  const order = [
                                    t({
                                      id: 'settings.users.form.label.pro',
                                      message: 'Pro',
                                    }),
                                    t({
                                      id: 'settings.users.form.label.default',
                                      message: 'Standaard',
                                    }),
                                  ]
                                  return (
                                    order.indexOf(b.label) -
                                    order.indexOf(a.label)
                                  )
                                })}
                                allowClear
                              />
                            </Form.Item>
                          </Space>
                          <MinusCircleOutlined
                            style={{ padding: 8, paddingRight: 0 }}
                            onClick={() => remove(name)}
                          />
                        </div>
                      ))}
                      <Divider style={{ marginTop: 0, marginBottom: 24 }} />
                      <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          {' '}
                          <Trans id="settings.users.form.add_role">
                            Nieuwe rol toewijzen
                          </Trans>
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                      <Divider />
                    </>
                  )}
                </Form.List>
              </Can>
            </Can>

            <Form.Item
              label={t({
                id: 'settings.users.form.label.language',
                message: 'Taal',
              })}
              name="lang"
            >
              <Select
                options={languages?.fetchLanguages.map((lang) => ({
                  label: lang.name,
                  value: lang.code,
                }))}
              />
            </Form.Item>

            {!!channels?.fetchChannels?.length && (
              <Form.Item
                label={t({
                  id: 'settings.users.form.label.channels',
                  message: 'Kennisbronnen',
                })}
                name="channels"
                tooltip={t({
                  id: 'settings.users.form.tooltip.channels',
                  message:
                    'Kies de kennisbronnen waartoe de gebruiker toegang heeft',
                })}
              >
                <Checkbox.Group options={channelOptions} />
              </Form.Item>
            )}

            {selectedChannels?.includes('6694dc0ae2c2cb44f79ac629') &&
              selectedChannels?.includes('6694dc0ee2c2cb44f79ac635') && (
                <Alert
                  type="warning"
                  style={{ marginBottom: 24 }}
                  description={
                    <Trans id="settings.users.form.warning.channels">
                      <strong>Let op</strong>: het aanvinken van meerdere
                      kennisbronnen heeft impact op de pricing voor deze
                      gebruiker.
                    </Trans>
                  }
                />
              )}

            {!!certificationTypes?.fetchCertificationTypes.filter(
              (type) =>
                !type.branch_id ||
                new ObjectId(type.branch_id).equals(branch?._id)
            )?.length && (
              <>
                <Form.Item
                  label={t({
                    id: 'settings.users.form.label.certification_types',
                    message: 'Certificeringstypes',
                  })}
                  name="certificationType"
                  help={
                    certificationTypes?.fetchCertificationTypes.filter(
                      (type) =>
                        type.branch_id &&
                        new ObjectId(type.branch_id).equals(branch?._id)
                    ).length > 0 ? (
                      <p>
                        {t({
                          id: 'settings.users.form.help.certification_types',
                          message: '* op afdelingsniveau',
                        })}
                      </p>
                    ) : null
                  }
                >
                  <Checkbox.Group
                    options={certificationTypes?.fetchCertificationTypes
                      .filter(
                        (type) =>
                          !type.branch_id ||
                          new ObjectId(type.branch_id).equals(branch?._id)
                      )
                      .map(
                        (type) =>
                          ({
                            label: `${type.name}${type.branch_id ? ' *' : ''}`,
                            value: type._id as string,
                          }) as CheckboxOptionType
                      )}
                  />
                </Form.Item>
                <Divider style={{ marginTop: 0, marginBottom: 24 }} />
              </>
            )}

            <CustomFieldsFormSection
              parent={FieldParent.User}
              fields={fields?.fetchFields}
              initialValues={selectedUser?.meta}
              admin={false}
            />

            {updateSubject && (
              <Form.Item
                name="disabled"
                valuePropName="checked"
                wrapperCol={{ sm: { offset: 8, span: 16 } }}
              >
                <Checkbox>
                  <Trans id="branch.users.form.label.disabled">
                    Account deactiveren
                  </Trans>
                </Checkbox>
              </Form.Item>
            )}
            {(!updateSubject || hasTenant) && !disabled && (
              <Form.Item
                name="notify"
                valuePropName="checked"
                wrapperCol={{ sm: { offset: 8, span: 16 } }}
              >
                <Checkbox>
                  {hasTenant ? (
                    <Trans id="settings.users.form.label.notify_has_tenant">
                      Gebruiker via e-mail op de hoogte brengen van account
                    </Trans>
                  ) : (
                    <Trans id="settings.users.form.label.notify">
                      Gebruiker via e-mail op de hoogte brengen van account
                      (inclusief wachtwoord)
                    </Trans>
                  )}
                </Checkbox>
              </Form.Item>
            )}
            <Alert
              type="warning"
              showIcon={true}
              message={
                <Trans id="branch.users.form.warning">
                  <strong>Let op</strong>: sommige browsers overschrijven
                  automatisch naam- en e-mailvelden. Kijk dit formulier dus goed
                  na voor je een wijziging doorvoert.
                </Trans>
              }
            />
            <Button
              hidden
              disabled={creating}
              type="primary"
              htmlType={'submit'}
            >
              <Trans id="action.save">Opslaan</Trans>
            </Button>
          </Form>
        </Space>
      </Modal>
    </>
  )
}
