/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable unicorn/no-nested-ternary */
import { DownOutlined } from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { Col, Empty, PageHeader, Pagination, Row } from 'antd'
import { ObjectId } from 'bson'
import { useOverlayScrollbars } from 'overlayscrollbars-react'
import { useContext, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  useQueryParam,
  StringParam,
  ArrayParam,
  NumberParam,
} from 'use-query-params'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  CourseQueryMeta,
  CoursesQuery,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext, Can } from '../../../auth/components/Can'
import { useAuth } from '../../../auth/hooks/use-auth'
import { LoadScreen, LoadSection } from '../../../core/components/LoadScreen'
import { DropdownButton } from '../../../shared/components/dynamic-dropdown-button/DropdownButton'
import { CourseCard } from '../../components/course-card/CourseCard'
import { CoursesFilters } from '../../components/courses-filters/CoursesFilters'
import { SoftwareCourses } from '../../components/software/SoftwareCourses'
import { useSoftware } from '../../hooks/use-software'

import COURSES_QUERY from './../../queries/courses.graphql'
import {
  Content,
  CoursesPaginationWrapper,
  CoursesStickyCol,
  CoursesWrapper,
} from './Courses.style'

export const Courses = () => {
  const { user } = useAuth()
  const contentRowRef = useRef<HTMLDivElement>(null)
  const filterRowRef = useRef<HTMLDivElement>(null)
  const ability = useContext(AbilityContext)
  const { search } = useLocation()
  const [page, setPage] = useQueryParam('page', NumberParam)
  const [pageSize, setPageSize] = useState<number>(12)
  const [searchTerm, setSearchTerm] = useQueryParam('q', StringParam)
  const [durationFilter, setDurationFilter] = useState<
    [number, number] | undefined
  >()
  const [categoryFilters, setCategoryFilters] = useQueryParam('cat', ArrayParam)
  const [branchCategoryFilters, setBranchCategoryFilters] = useQueryParam(
    'bcat',
    ArrayParam
  )
  const [certificationTypeFilters, setCertificationTypeFilters] = useQueryParam(
    'ctype',
    ArrayParam
  )
  const [typeFilters, setTypeFilters] = useQueryParam('type', ArrayParam)
  const [levelFilters, setLevelFilters] = useState<string[]>([])
  const [languageFilters, setLanguageFilters] = useState<string[]>(
    user?.lang ? [user?.lang] : []
  )
  const [revisionDateFilters, setRevisionDateFilters] = useState<number[]>([])
  const [statusFilter, setStatusFilter] = useState<boolean | undefined>()

  const {
    active: softwareNMoreActive,
    vendors,
    loading,
  } = useSoftware(typeFilters)

  useEffect(() => {
    if (softwareNMoreActive) {
      setCategoryFilters([])
      setCertificationTypeFilters([])
      setDurationFilter(undefined)
      setLanguageFilters([])
    }
  }, [softwareNMoreActive, setCategoryFilters, setCertificationTypeFilters])

  const navigate = useNavigate()

  const [meta, setMeta] = useState<CourseQueryMeta>()

  const [fetch, { data }] = useLazyQuery<CoursesQuery>(COURSES_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setMeta(data.fetchCourses.meta)
    },
  })

  const [initialize] = useOverlayScrollbars({
    options: {
      scrollbars: {
        autoHide: 'scroll',
        visibility: 'auto',
      },
    },
    defer: true,
  })

  useEffect(() => {
    if (filterRowRef.current) initialize(filterRowRef.current)
  }, [initialize, filterRowRef])

  useEffect(() => {
    sessionStorage.setItem('aa_filters', search)
  }, [search])

  useEffect(() => {
    if (!softwareNMoreActive) {
      sessionStorage.removeItem('aa_software_scrolly')
    }
  }, [softwareNMoreActive])

  useEffect(() => {
    fetch({
      variables: {
        query: searchTerm,
        type: typeFilters
          ?.filter(
            (id) =>
              id &&
              (ObjectId.isValid(id) ||
                ObjectId.isValid(id.split('.').at(-1) || ''))
          )
          .reduce<string[]>((prev, cur, _index, arr) => {
            // If the current value is a prefix (parent) of another value in the array, we don't want to include it
            if (cur && arr.some((i) => i?.startsWith(cur) && i !== cur)) {
              return prev
            }
            // If the current value is a child, we get the last part of the string (the actual type id)
            else if (cur?.split('.').length && cur?.split('.').length > 1) {
              return [...prev, cur.split('.').at(-1) || '']
            }
            // Otherwise, we just include the value as is
            else if (cur) {
              return [...prev, cur]
            } else return prev
          }, []),
        certificationType: certificationTypeFilters,
        duration: durationFilter,
        category: [
          ...(categoryFilters || []),
          ...(branchCategoryFilters || []),
        ],
        language: languageFilters,
        level: levelFilters,
        status: statusFilter,
        revision_date: revisionDateFilters,
        page: page || 1,
        limit: pageSize,
      },
    })
    window.scrollTo({
      top: Math.max((contentRowRef.current?.offsetTop || 0) - 24, 0),
      behavior: 'smooth',
    })
  }, [
    page,
    pageSize,
    searchTerm,
    typeFilters,
    certificationTypeFilters,
    durationFilter,
    categoryFilters,
    branchCategoryFilters,
    levelFilters,
    statusFilter,
    revisionDateFilters,
    languageFilters,
    fetch,
  ])

  return (
    <>
      <Content>
        <PageHeader
          style={{ marginTop: -8, padding: 0, paddingBottom: 20 }}
          subTitle={
            softwareNMoreActive && (
              <h2>
                <Trans id="courses.software.alert">
                  Nood aan een bepaalde opleiding van onze partners?
                </Trans>{' '}
                <a
                  href="https://accountantsacademy.be/contact/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Trans id="courses.software.alert.cta">
                    Neem contact op.
                  </Trans>
                </a>
              </h2>
            )
          }
          extra={
            <Can
              I={PermissionAction.CREATE}
              a={PermissionObjectType.COURSE}
              key="2"
            >
              <DropdownButton
                icon={<DownOutlined />}
                type="primary"
                menu={{
                  hidden: !(
                    ability.can(
                      PermissionAction.IMPORT,
                      PermissionObjectType.COURSE
                    ) ||
                    ability.can(
                      PermissionAction.IMPORT,
                      PermissionObjectType.BRANCH_COURSE
                    )
                  ),
                  onClick: ({ key }) => navigate(key),
                  items: [
                    {
                      key: 'import',
                      label: t({
                        id: 'courses.action.import',
                        message: 'Opleidingen importeren',
                      }),
                    },
                  ],
                }}
              >
                <Link className={'btn-link'} to={'/courses/add'}>
                  <Trans id="courses.action.add">Opleiding aanmaken</Trans>
                </Link>
              </DropdownButton>
            </Can>
          }
        />
        <Row gutter={[16, 16]} ref={contentRowRef}>
          <CoursesStickyCol
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            lg={{ span: 6 }}
            xl={{ span: 6 }}
          >
            <CoursesWrapper ref={filterRowRef}>
              <CoursesFilters
                query={searchTerm || ''}
                onQueryChanged={(val) => {
                  setSearchTerm(val)
                  setPage(1)
                }}
                type={typeFilters}
                certificationType={certificationTypeFilters}
                onCertificationTypeChanged={(val) =>
                  setCertificationTypeFilters(val)
                }
                onTypeChanged={(val) => setTypeFilters(val)}
                category={categoryFilters}
                onBranchCategoryChanged={(val) => setBranchCategoryFilters(val)}
                onCategoryChanged={(val) => setCategoryFilters(val)}
                onLevelChanged={(val) => setLevelFilters(val)}
                onStatusChanged={(val) => setStatusFilter(val)}
                onRevisionChanged={(val) => setRevisionDateFilters(val)}
                onDurationChanged={(val) => setDurationFilter(val)}
                onLanguageChanged={(val) => setLanguageFilters(val)}
                meta={meta || data?.fetchCourses.meta}
                permissions={{
                  canReadCourseCategory:
                    ability.can(
                      PermissionAction.READ,
                      PermissionObjectType.COURSE_CATEGORY
                    ) ||
                    ability.can(
                      PermissionAction.READ,
                      PermissionObjectType.BRANCH_COURSE_CATEGORY
                    ),
                  canReadCourseType: ability.can(
                    PermissionAction.READ,
                    PermissionObjectType.COURSE_TYPE
                  ),
                }}
              />
            </CoursesWrapper>
          </CoursesStickyCol>
          {softwareNMoreActive && (
            <Col
              sm={{ span: 12 }}
              lg={{ span: 18 }}
              xl={{ span: 18 }}
              style={{
                marginBottom: softwareNMoreActive ? 0 : -30,
                width: '100%',
              }}
            >
              {loading || !data?.fetchCourses ? (
                <LoadSection />
              ) : (
                <SoftwareCourses
                  courses={data?.fetchCourses?.results}
                  vendors={vendors}
                />
              )}
            </Col>
          )}
          {!softwareNMoreActive && (
            <Col
              sm={{ span: 12 }}
              lg={{ span: 18 }}
              xl={{ span: 18 }}
              style={{ marginBottom: -30 }}
            >
              <Row gutter={16}>
                {data?.fetchCourses ? (
                  data?.fetchCourses.results.length === 0 ? (
                    <Col xs={24}>
                      <Empty
                        style={{ paddingTop: 24 }}
                        description={t({
                          id: 'courses.empty',
                          message: 'Geen opleidingen gevonden.',
                        })}
                      />
                    </Col>
                  ) : (
                    [...data.fetchCourses.results]
                      .sort((a, b) => {
                        if (!(searchTerm?.length && searchTerm.length > 1)) {
                          // If not searching, trust on back-end sort order
                          return 0
                        }

                        return searchTerm?.length && searchTerm.length > 1
                          ? 1
                          : !!a.published === !!b.published
                          ? 0
                          : a.published
                          ? 1
                          : -1
                      })
                      .map((course) => (
                        <CourseCard
                          key={(page || 1) + course._id}
                          card
                          progress
                          course={course}
                          draft={!course.published}
                          likes={ability.can(
                            PermissionAction.READ,
                            PermissionObjectType.LIKE
                          )}
                          addToCalendar={ability.can(
                            PermissionAction.ADD_TO_CALENDAR,
                            PermissionObjectType.COURSE
                          )}
                          cardsPerRow={{ sm: 24, lg: 8, xl: 8 }}
                          onClick={(course) =>
                            navigate(
                              `/courses/${encodeURIComponent(course.slug)}`
                            )
                          }
                          pinnable
                          archived={course.category?.some(
                            (cat) => cat.name === 'Archief'
                          )}
                        />
                      ))
                  )
                ) : (
                  <Col xs={24}>
                    <LoadScreen />
                  </Col>
                )}
              </Row>
            </Col>
          )}
        </Row>
        {!softwareNMoreActive && (
          <Row>
            <Col span={24}>
              <CoursesPaginationWrapper>
                {data?.fetchCourses &&
                  data?.fetchCourses.count >
                    data.fetchCourses?.results.length && (
                    <Pagination
                      current={page || 1}
                      onChange={(page) => setPage(page)}
                      defaultPageSize={pageSize}
                      pageSizeOptions={[9, 12, 15, 18, 21, 24, 27, 30]}
                      onShowSizeChange={(_, size: number) => setPageSize(size)}
                      total={data?.fetchCourses.count}
                    />
                  )}
              </CoursesPaginationWrapper>
            </Col>
          </Row>
        )}
      </Content>
    </>
  )
}
